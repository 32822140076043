<template>

    <div class="row match-height">
      <div class="col-lg-4">
          <b-card>
                  <vue-apex-charts
                  :series="[(this.CashRatio/2)*100]"
                  :options="RatioChart.chartOptions"/>
                  <b-alert
                    :variant="alertColor([(this.CashRatio/2)*100])"
                    show
                  >
                    <div class="alert-body">
                     <span>{{alertText(([(this.CashRatio/2)*100]))}}</span>
                    </div>
                  </b-alert>
              </b-card>
      </div>
      <div class="col-lg-4 h-100 ">
          
        
        <card-text-alignment class="h-100"/>
                  
          
      </div>
      <div class="col-lg-4 ">
          <b-card>
                  <vue-apex-charts
                  :series="[(this.CurrentRatio/2)*100]"
                  :options="RatioChart.chartOptions"/>
              </b-card>
      </div>
  </div>

</template>




<script>
    import {
      BCard, BCardBody, BCardHeader, BCardTitle, BAlert
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import flatPickr from 'vue-flatpickr-component'
    import apexChatData from './apexChartData'
    import { $themeColors } from '@themeConfig'
    import CardTextAlignment from './CardTextAlignment.vue'
    
    export default {
      components: {
        BCard,
        BCardBody,
        BCardHeader,
        VueApexCharts,
        flatPickr,
        BCardTitle,
        CardTextAlignment,
        BAlert
      },
      computed: {
        Name() {
          this.$store.getters.getCurrentCompdata.Name
        },
        CurrentRatio() {
          return [this.$store.getters.getCurrentCompdata.CurrentRatio];
        },
        CashRatio() {
          return [this.$store.getters.getCurrentCompdata.CashRatio];
        }
      },
      data() {
        return {
          apexChatData,
          rangePicker: ['2019-05-01', '2019-05-10'],
          RatioChart: {
        series: [40],
        chartOptions: {

          chart: {
          height: 400,
          type: 'radialBar',
          toolbar: {
            show: false,
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
             hollow: {
              margin: 0,
              size: '70%',
              background: "",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
            //   dropShadow: {
            //     enabled: true,
            //     top: 3,
            //     left: 0,
            //     blur: 4,
            //     opacity: 0.24
            //   }
            },
            track: {
              background: '$bg-light',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
            //   dropShadow: {
            //     enabled: true,
            //     top: -3,
            //     left: 0,
            //     blur: 4,
            //     opacity: 0.35
            //   }
            },
        
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '$text-muted',
                fontSize: '17px'
              },
              value: {
                formatter: function(val) {
                  return parseInt(val);
                },
                color: '#$text-muted',
                fontSize: '36px',
                show: true,
              }
            }
          }
        },
        fill: {
          colors: [function({ value, seriesIndex, w }) {
            if(value < 55) {
                return $themeColors.danger
            } else if (value >= 55 && value < 80) {
                return $themeColors.warning
            } else {
                return $themeColors.success
            }
          }]
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Current-Ratio'],
        }
         } 
        }
    },
    methods: {
      alertColor(value) {
            if(value < 55) {
                return 'danger'
            } else if (value >= 55 && value < 80) {
                return 'warning'
            } else {
                return 'success'
            }
          },
      alertText(value) {
            if(value < 55) {
                return "That's bad. The business might struggle to meet its immediate financial responsibilities."
            } else if (value >= 55 && value < 80) {
                return "Not ideal!. The company might have problems to fulffill its short-term obligations"
            } else {
                return "Perfect!. The business won't have any issues meeting its immediate responsibilities."
            }
          },
      }
    }

</script>