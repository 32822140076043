<template>
  <section id="dashboard-analytics">
    <app-tour :steps="steps"/>
    <b-row class="row-eq-height">
      <b-col lg="4"  class="h-100">
        <cash-ratio />
      </b-col>
      <b-col lg="4" class="h-100">
        <card-text-alignment-vue />
      </b-col>
      <b-col lg="4"  class="h-100">
        <current-ratio/>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <apex-data-bar-chart-vue />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'

import CashRatio from './CashRatio.vue'
import CurrentRatio from './CurrentRatio.vue'
import RatioChartVue from './RatioChart.vue'
import ApexDataBarChartVue from './ApexDataBarChart.vue'
import CardTextAlignmentVue from './CardTextAlignment.vue'
import { EventBus } from '@/main'
import AppTour from '@core/components/app-tour/AppTour.vue'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    CashRatio,
    CurrentRatio,
    ApexDataBarChartVue,
    RatioChartVue,
    CardTextAlignmentVue,
    AppTour

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
      steps: [
            {
              target: '#TextArea',
              header: {
                title: 'Welcome Back',
              },
              content: 'This Site let you analyze the Liquididy and Solvency of your currently selected Company. If you have any questions you can click the Explain it to me button or if you like what you are seeing you can add this company to your Watchlist',
            },
            {
              target: '#CashRatio',
              header: {
                title: 'Liquidity 1',
              },
              content: 'Here you can see the score of the the Cash-Ratio of your currently selected Company the colored field explains if its good or bad',
            },
            {
              target: '#CurrentRatio',
              header: {
                title: 'Liquidity 2',
              },
              content: 'Here you can see the score of the the Current just like with the Cash-Ratio the colored field helps you to put it into perspective',
            },
            {
              target: '#Debt2Equity',
              header: {
                title: 'Solvency',
              },
              content: 'the Debt to Equity graph shows you the most commonly used ratio for solvency.',
            },
            {
              target: '#Debt2Equity',
              header: {
                title: 'Next',
              },
              content: 'Financial Strength is already a good start, but is your company growing? Lets find out by clicking at the Growth Section in the Sidebar',
            },
            {
              target: '#__BVID__1094___BV_tab_button__',
              header: {
                title: 'Next',
              },
              content: 'Financial Strength is already a good start, but is your company growing? Lets find out by clicking at the Growth Section',
            }
          ],
    }
  },
  methods: {
    kFormatter,
    checkTutorial() {
      if(this.$store.getters.getUser.tutorial === true) {
        this.$tours.vuexyTour.start()
      }
    },
    
  },
  mounted() {
    this.checkTutorial()
  },  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/tour.scss';
</style>
