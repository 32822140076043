<template>

        <b-card 
        id="TextArea"
          title="Which Metric are relevant to measure Growth"
        >
          <b-card-text>
            You might be familiar with the terms: Cash Ratio, Current Ratio, and Debt-to-Equity Ratio. If you are not familiar with them yet, you can click below to learn what these terms mean and why they are the most relevant metrics to measure a company's financial strength. Understanding these ratios is crucial for assessing a company's ability to manage its short-term obligations, liquidity position, and its financial leverage, which are key factors in determining financial strength.          </b-card-text>
          <div>
            <b-button 
            block
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-center
            :variant="variant()"
          >
            Explain it to me
          </b-button>
          <b-button
          
          block 
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
             variant="outline-secondary"
          >
            Add to Watchlist
          </b-button>
          </div>
          <b-modal
      id="modal-center"
      centered
      title="Financial Ratios Analysis"
      ok-only
      ok-title="Accept"
    >
      <b-card-text>
        <h2>Cash Ratio:</h2>
    <p>
        <strong>Definition:</strong> The Cash Ratio measures a company's capacity to meet short-term liabilities using its most liquid assets.
    </p>
    <p>
        <strong>Formula:</strong> Cash Ratio = (Cash and Cash Equivalents) / (Current Liabilities)
    </p>

    <h2>Debt to Equity Ratio:</h2>
    <p>
        <strong>Definition:</strong> The Debt to Equity Ratio reflects the proportion of a company's financing sourced from debt compared to equity.
    </p>
    <p>
        <strong>Formula:</strong> Debt to Equity Ratio = (Total Debt) / (Total Equity)
    </p>

    <h2>Current Ratio:</h2>
    <p>
        <strong>Definition:</strong> The Current Ratio evaluates a company's ability to cover short-term obligations with short-term assets, providing insights into liquidity.
    </p>
    <p>
        <strong>Formula:</strong> Current Ratio = (Current Assets) / (Current Liabilities)
    </p>
      </b-card-text>
    </b-modal>
        </b-card>
    
        
        

</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton,BModal
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow, BCol, BCard, BCardText, BButton,
  },
  directives: {
    Ripple,
  },
  computed: {
    skin() {
      return this.$store.state.appConfig.layout.skin
    },
  },
  methods: {
    variant: function () {
      if ( this.skin === 'light') {
        return "dark"
      }if (this.skin === 'dark') {
        return "light"
      }
    },
  },
}
</script>
