<template>
  <b-card id="CashRatio">
    <div class="d-flex justify-content-between align-items-start">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-popover.click.bottom="'The cash ratio is a financial metric that measures a company\'s ability to pay off its short-term liabilities using only its cash and cash equivalents. In simple terms, it\'s a way of assessing how well a business can cover its immediate debts with the cash it currently has on hand.'"
        title="Cash-Ratio"
        variant="flat-secondary"
        class="btn-icon"
      >
        <feather-icon icon="HelpCircleIcon" />
      </b-button>
      <b-dropdown v-model="selectedRatio" right
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :text="this.selectedRatio"
            variant="outline-secondary"
            :disabled="this.disabled"
      >
        <b-dropdown-item @click="changeRatio('CurrentRatio')">
          Current-Ratio
        </b-dropdown-item>
        <b-dropdown-item @click="changeRatio('CashRatio')">
          Cash-Ratio
        </b-dropdown-item>
        <b-dropdown-item @click="changeRatio('DaysInventory')">
          Days Inventory
        </b-dropdown-item>
        <b-dropdown-item @click="changeRatio('DaysSalesOutstanding')">
          Days Sales Outstanding
        </b-dropdown-item>
        <b-dropdown-item @click="changeRatio('DaysPayable')">
          Days Payable
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <vue-apex-charts
      :series="getChartSeries()"
      :options="getChartOptions()"
    />
    <b-alert :variant="alertColor(getChartSeries()[0])" show>
      <div class="alert-body">
        <span>{{ alertText(getChartSeries()[0]) }}</span>
      </div>
    </b-alert>
  </b-card>
</template>
<script>
    import {
      BCard, BCardBody, BCardHeader, BCardTitle, BAlert, VBTooltip, BDropdown, BDropdownItem
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import flatPickr from 'vue-flatpickr-component'
    import apexChatData from './apexChartData'
    import { $themeColors } from '@themeConfig'
    import CardTextAlignment from './CardTextAlignment.vue'
    import {VBPopover, BButton} from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
    
    export default {
      components: {
        BCard,
        BCardBody,
        BCardHeader,
        VueApexCharts,
        flatPickr,
        BCardTitle,
        CardTextAlignment,
        BAlert,
        BButton, BDropdown, BDropdownItem
        
      },
      directives: {
        'b-popover': VBPopover,
        Ripple,
      },
      computed: {
        Name() {
          this.$store.getters.getCurrentCompdata.Name
        },
        CurrentRatio() {
          return [this.$store.getters.getCurrentCompdata.CurrentRatio];
        },
        CashRatio() {
          return [this.$store.getters.getCurrentCompdata.CashRatio];
        }
      },
      data() {
        return {
          selectedRatio: 'CashRatio',
          apexChatData,
          rangePicker: ['2019-05-01', '2019-05-10'],
          RatioChart: {
        series: [40],
        chartOptions: {

          chart: {
          type: 'radialBar',
          toolbar: {
            show: false,
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
             hollow: {
              margin: 0,
              size: '70%',
              background: "",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
            //   dropShadow: {
            //     enabled: true,
            //     top: 3,
            //     left: 0,
            //     blur: 4,
            //     opacity: 0.24
            //   }
            },
            track: {
              background: '$bg-light',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
            //   dropShadow: {
            //     enabled: true,
            //     top: -3,
            //     left: 0,
            //     blur: 4,
            //     opacity: 0.35
            //   }
            },
        
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '$text-muted',
                fontSize: '17px'
              },
              value: {
                formatter: function(val) {
                  return parseInt(val);
                },
                color: '#$text-muted',
                fontSize: '36px',
                show: true,
              }
            }
          }
        },
        fill: {
          colors: [function({ value, seriesIndex, w }) {
            if(value < 55) {
                return $themeColors.danger
            } else if (value >= 55 && value < 80) {
                return $themeColors.warning
            } else {
                return $themeColors.success
            }
          }]
        },
        stroke: {
          lineCap: 'round'
        },
        labels: [this.getLabel()],
        }
         } 
        }
    },
    created() {
      this.CheckUser()
    },
    methods: {
      CheckUser() {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            this.disabled = false
          } else {
            console.log('Error')
            this.disabled = true
          }
        })
      },
      alertColor(value) {
            if(value < 55) {
                return 'danger'
            } else if (value >= 55 && value < 80) {
                return 'warning'
            } else {
                return 'success'
            }
          },
      alertText(value) {
            if(value < 55) {
                return "That's bad. The business might struggle to meet its immediate financial responsibilities with only cash."
            } else if (value >= 55 && value < 80) {
                return "Not ideal!. The company might have problems to fulffill its short-term obligations with only cash."
            } else {
                return "Perfect!. The business won't have any issues meeting its immediate responsibilities with only cash."
            }
          },
          getChartOptions() {
      return {
        chart: {
          type: 'radialBar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '70%',
              background: '',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
            },
            track: {
              background: '$bg-light',
              strokeWidth: '67%',
              margin: 0,
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '$text-muted',
                fontSize: '17px',
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: '#$text-muted',
                fontSize: '36px',
                show: true,
              },
            },
          },
        },
        fill: {
          colors: [function ({ value }) {
            if (value < 55) {
              return $themeColors.danger;
            } else if (value >= 55 && value < 80) {
              return $themeColors.warning;
            } else {
              return $themeColors.success;
            }
          }],
        },
        stroke: {
          lineCap: 'round',
        },
        labels: [this.getLabel()],
      };
    },

    getLabel() {
      return this.selectedRatio === 'CurrentRatio'
        ? 'Current-Ratio'
        : 'Cash-Ratio';
    },

    getChartSeries() {
      return this.selectedRatio === 'CurrentRatio'
        ? [this.CurrentRatio[0] / 2 * 100]
        : [this.CashRatio[0] / 2 * 100];
    },

    changeRatio(ratio) {
      this.selectedRatio = ratio;
    },
  },
};
</script>

<!-- <style>
  .btn-dropdown {
    background-color: #fff !important;
  }
</style> -->
