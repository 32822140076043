<template>
  <b-card no-body id="Debt2Equity">
    <b-card-title class="mt-3 ml-2">
      {{ selectedSeries1 }} -To- {{ selectedSeries2 }}
    </b-card-title>
    <b-card-header>
      
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="dropdown">
          <!-- primary -->
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :text="selectedSeries1"
            variant="outline-warning"
            class="mr-2"
          >
            <b-dropdown-item @click="selectedSeries1 = 'Debt'; updateChart()">Debt</b-dropdown-item>
            <b-dropdown-item @click="selectedSeries1 = 'Equity'; updateChart()">Equity</b-dropdown-item>
            <b-dropdown-item @click="selectedSeries1 = 'Cash'; updateChart()">Cash</b-dropdown-item>
            <b-dropdown-item @click="selectedSeries1 = 'Assets'; updateChart()">Assets</b-dropdown-item>
            <b-dropdown-item @click="selectedSeries1 = 'EBIT'; updateChart()">EBIT</b-dropdown-item>
            <b-dropdown-item @click="selectedSeries1 = 'EBITDA'; updateChart()">EBITDA</b-dropdown-item>
            <!-- Add more options if needed -->
          </b-dropdown>
        </div>
        <div class="dropdown">
          <!-- info -->
          <b-dropdown
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            :text="selectedSeries2"
            variant="outline-success"
          >
            <b-dropdown-item @click="selectedSeries2 = 'Debt'; updateChart()">Debt</b-dropdown-item>
            <b-dropdown-item @click="selectedSeries2 = 'Equity'; updateChart()">Equity</b-dropdown-item>
            <b-dropdown-item @click="selectedSeries2 = 'Cash'; updateChart()">Cash</b-dropdown-item>
            <b-dropdown-item @click="selectedSeries2 = 'Assets'; updateChart()">Assets</b-dropdown-item>
            <b-dropdown-item @click="selectedSeries2 = 'EBIT'; updateChart()">EBIT</b-dropdown-item>
            <b-dropdown-item @click="selectedSeries2 = 'EBITDA'; updateChart()">EBITDA</b-dropdown-item>
            <!-- Add more options if needed -->
          </b-dropdown>
        </div>
      </div>
      <!--/ Dropdown menus -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="400"
        :options="apexChatData.columnChart.chartOptions"
        :series="[selectedSeriesData1, selectedSeriesData2]"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BCardTitle, BDropdown,BDropdownItem  } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
    BDropdown,
    BDropdownItem 
  },
  computed: {
    selectedSeriesData1() {
      return this.getSelectedSeriesData(this.selectedSeries1)
    },
    selectedSeriesData2() {
      return this.getSelectedSeriesData(this.selectedSeries2)
    },
  },
  data() {
    return {
      apexChatData,
      selectedSeries1: 'Debt', // Default selected series for dropdown 1
      selectedSeries2: 'Equity', // Default selected series for dropdown 2
    }
  },
  created() {
    this.updateXAxisLabels()
  },
  methods: {
    updateXAxisLabels() {
      const xaxis = this.apexChatData.columnChart.chartOptions.xaxis
      // Set the categories to be the keys of TotalDebt or TotalEquity object
      xaxis.categories = Object.keys(this.$store.getters.getCurrentCompdata.TotalDebt)
    },
    getSelectedSeriesData(selectedSeries) {
      if (selectedSeries === 'Debt') {
        if (this.$store.getters.getCurrentCompdata.TotalDebt === undefined) {
          return { name: 'Debt', data: [] }
        }
        return { name: 'Debt', data: Object.values(this.$store.getters.getCurrentCompdata.TotalDebt) }
      } else if (selectedSeries === 'Equity') {
        if (this.$store.getters.getCurrentCompdata.TotalEquity === undefined) {
          return { name: 'Equity', data: [] }
        }
        return { name: 'Equity', data: Object.values(this.$store.getters.getCurrentCompdata.TotalEquity) }
      } else if (selectedSeries === 'Cash') {
        if (this.$store.getters.getCurrentCompdata.BalanceSheet === undefined ||
            this.$store.getters.getCurrentCompdata.BalanceSheet.Cash === undefined) {
          return { name: 'Cash', data: [] }
        }
        return { name: 'Cash', data: Object.values(this.$store.getters.getCurrentCompdata.BalanceSheet.Cash) }
      } else if (selectedSeries === 'Assets') {
        if (this.$store.getters.getCurrentCompdata.BalanceSheet === undefined ||
            this.$store.getters.getCurrentCompdata.BalanceSheet.totalAssets === undefined) {
          return { name: 'Assets', data: [] }
        }
        return { name: 'Assets', data: Object.values(this.$store.getters.getCurrentCompdata.BalanceSheet.totalAssets) }
      } else if (selectedSeries === 'EBIT') {
        if (this.$store.getters.getCurrentCompdata.IncomeStatement === undefined ||
            this.$store.getters.getCurrentCompdata.IncomeStatement.ebit === undefined) {
          return { name: 'EBIT', data: [] }
        }
        return { name: 'EBIT', data: Object.values(this.$store.getters.getCurrentCompdata.IncomeStatement.ebit) }
      } else if (selectedSeries === 'EBITDA') {
        if (this.$store.getters.getCurrentCompdata.IncomeStatement === undefined ||
            this.$store.getters.getCurrentCompdata.IncomeStatement.ebitda === undefined) {
          return { name: 'EBITDA', data: [] }
        }
        return { name: 'EBITDA', data: Object.values(this.$store.getters.getCurrentCompdata.IncomeStatement.ebitda) }
      }
      // Handle other cases if needed
    },
    updateChart() {
      // Method to update the chart when the dropdown selection changes
      // No need to implement anything here as the chart will automatically update with the computed properties
    },
  },
}
</script>
